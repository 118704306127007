import { render, staticRenderFns } from "./RegisterForRedeem.vue?vue&type=template&id=3ee07945&scoped=true"
import script from "./js/register-for-redeem.js?vue&type=script&lang=js&external"
export * from "./js/register-for-redeem.js?vue&type=script&lang=js&external"
import style0 from "./RegisterForRedeem.vue?vue&type=style&index=0&id=3ee07945&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ee07945",
  null
  
)

export default component.exports